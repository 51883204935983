export default {
  title: 'تنظیمات',

  importantData: {
    balance: 'موجودی',
    employeeCount: 'تعداد کارمندان'
  },

  buttons: {
    save: 'ذخیره تغییرات',
    reset: 'برگرداندن تغییرات',
    removeLogo: 'حذف لوگو',
    uploadLogo: 'بارگزاری لوگو'
  },

  companyInfo: {
    title: 'اطلاعات شرکت',

    labels: {
      generalInfo: 'اطلاعات عمومی',
      telInfo: 'اطلاعات تماس',
      financialInfo: 'اطلاعات مالی',
      uploadLogo: 'آپلود لوگو',
      preview: 'پیشنمایش:',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      country: 'کشور',
      province: 'استان',
      city: 'شهر',
      address: 'آدرس',
      postCode: 'کد پستی',
      phoneNumber: 'شماره تماس',
      whatsAppSupport: 'پشتیبانی واتساپ',
      managerPhoneNumber: 'شماره تماس مدیر'
    },

    description: {
      logoRule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت',
      generalInfo: 'در این باکس اطلاعات عمومی شرکت نمایش داده می شود.',
      telInfo: 'در این باکس اطلاعات تماس شرکت نمایش داده می شود.',
      financialInfo: 'در این باکس اطلاعات مالی شرکت نمایش داده می شود.'
    },

    validators: {
      imageSize: 'حجم فایل انتخاب شده بیش از حد مجاز است',
      fileFormat: 'فرمت فایل انتخاب شده مجاز نیست',
      name: 'نام وارد شده معتبر نمیباشد'
    },

    notifications: {
      logo: {
        upload: {
          success: 'لوگو با موفقیت آپلود شد'
        },
        delete: {
          success: 'لوگو با موفقیت حذف شد'
        }
      },

      edit: {
        success: 'اطلاعات فروشگاه با موفقیت بروز شد'
      }
    }
  },

  accessGroup: {
    title: 'گروه های کاربری',

    notifications: {
      insert: {
        success: 'گروه کاربری با موفقیت اضافه شد',
        error: 'افزودن گروه کاربری با خطا همراه شد'
      },

      edit: {
        success: 'گروه کاربری با موفقیت ویرایش شد',
        error: 'ویرایش گروه کاربری با خطا همراه شد'
      },

      delete: {
        success: 'گروه کاربری با موفقیت حذف شد',
        error: 'گروه کاربری مورد نظر یافت نشد.',
        cantDelete: 'گروه کاربری مورد نظر به دلیل داشتن زیر گروه, قابل حذف نمی باشد.'
      },

      deleteUser: {
        success: 'کاربر با موفقت از گروه کاربری حذف شد',
        error: 'حذف کاربر از گروه کاربری با خطا همراه شد',
      }
    },

    confirmations: {
      delete: {
        title: 'حذف گروه کاربری',
        body: 'آیا از حذف  {name} اطمینان دارید؟'
      },

      deleteUser: {
        title: 'حذف کاربر از گروه کاربری',
        body: 'آیا از حذف  {name} از این گروه کاربری اطمینان دارید؟'
      }
    },

    permissions: {
      'event_menu': 'پیام ها',
      'event': {
        'show': 'مشاهده پیام ها',
        'create': 'افزودن پیام',
        'update': 'ویرایش پیام',
        'delete': 'حذف پیام'
      },
      'user_management_menu': 'اشخاص',
      'user_menu': 'لیست اشخاص',
      'user': {
        'show': 'مشاهده اشخاص',
        'show_transactions': 'مشاهده گردش اشخاص',
        'create': 'افزودن شخص',
        'update': 'ویرایش کامل شخص',
        'delete': 'حذف موقت شخص',
        'restore': 'بازیابی شخص',
        'forceDelete': 'حذف دائم شخص',
        'set_role': 'اختصاص نقش به شخص',
        'set_group': 'اختصاص گروه مشتریان به شخص',
        'set_max_debt': 'اختصاص اعتبار به شخص'
      },
      'user_group_menu': 'گروه مشتریان',
      'user_group': {
        'show': 'مشاهده گروه مشتریان',
        'create': 'افزودن گروه مشتریان',
        'update': 'ویرایش کامل گروه مشتریان',
        'delete': 'حذف موقت گروه مشتریان',
        'restore': 'بازگردانی گروه مشتریان',
        'forceDelete': 'حذف دائم گروه مشتریان',
        'assign_to_user': 'اختصاص گروه مشتریان به مشتری'
      },
      'role_menu': 'سطوح دسترسی',
      'role': {
        'show': 'مشاهده سطوح دسترسی',
        'create': 'افزودن سطح دسترسی',
        'update': 'ویرایش کامل سطح دسترسی',
        'delete': 'حذف سطح دسترسی'
      },
      'product_and_services_menu': 'کالا و خدمات',
      'products_menu': 'لیست کالا',
      'services_menu': 'لیست خدمات',
      'service': {
        'show': 'مشاهده خدمات',
        'create': 'افزودن خدمت جدید',
        'update': 'ویرایش خدمت',
        'delete': 'حذف خدمت',
        'restore': 'بازگردانی خدمت',
        'forceDelete': 'حذف دائم خدمت'
      },
      'categories_menu': 'دسته بندی کالا',
      'attributes_menu': 'ویژگی ها',
      'attribute': {
        'show': 'مشاهده ویژگی ها',
        'create': 'افزودن ویژگی',
        'update': 'ویرایش ویژگی ها',
        'updatePriorities': 'ویرایش اولیت ویژگی ها',
        'delete': 'حذف ویژگی'
      },
      'attribute_values_menu': 'مقادیر ویژگی',
      'attribute_value': {
        'show': 'مشاهده مقادیر ویژگی ها',
        'create': 'افزودن مقدار ویژگی',
        'update': 'ویرایش مقدار ویژگی',
        'delete': 'حذف مقدار ویژگی'
      },
      'warehousing_menu': 'انبارداری',
      'storeroom_menu': 'انبارها',
      'storeroom': {
        'show': 'مشاهده انبار',
        'create': 'افزودن انبار',
        'update': 'ویرایش کامل انبار',
        'delete': 'حذف موقت انبار',
        'forceDelete': 'حذف دائم انبار',
        'restore': 'بازیابی انبار',
        'turnover': 'مشاهده گردش کالا انبار',
        'inventory': 'مشاهده مرور تعدادی انبار',
        'inventoryValuation': 'مشاهده مرور مبلغی انبار'
      },
      'storeroom_receipts_menu': 'حواله های انبار',
      'storeroom_receipt': {
        'show': 'مشاهده حواله انبار',
        'create': 'افزودن حواله انبار',
        'update': 'ویرایش کامل حواله انبار',
        'refresh_status': 'تغییر وضعیت حواله انبار',
        'accept': 'تائید حواله انبار',
        'partial_accept': 'تائید جزئی حواله انبار',
        'delete': 'حذف موقت حواله انبار'
      },
      'storeroom_deficits_menu': 'کسورات و اضافات انبار',
      'storeroom_deficit': {
        'show': 'مشاهده کسورات و اضافات انبار',
        'create': 'افزودن کسورات و اضافات انبار',
        'update': 'ویرایش کسورات و اضافات انبار',
        'fullUpdate': 'ویرایش کامل کسورات و اضافات انبار',
        'delete': 'حذف موقت کسورات و اضافات انبار'
      },
      'storeroom_conversions_menu': 'تبدیلات موجودی انبار',
      'storeroom_conversion': {
        'show': 'مشاهده تبدیلات موجودی انبار',
        'create': 'افزودن تبدیل موجودی انبار',
        'update': 'ویرایش تبدیل موجودی انبار',
        'fullUpdate': 'ویرایش کامل تبدیل موجودی انبار',
        'delete': 'حذف موقت تبدیل موجودی انبار'
      },
      'treasury_menu': 'خزانه داری',
      'bank_menu': 'بانک ها',
      'bank': {
        'show': 'مشاهده بانک ها',
        'show_transactions': 'مشاهده گردش بانک ها',
        'create': 'افزودن بانک',
        'update': 'ویرایش بانک',
        'delete': 'حذف بانک',
        'restore': 'بازگردانی بانک',
        'forceDelete': 'حذف دائم بانک'
      },
      'payment_gateway_menu': 'درگاه های پرداخت',
      'payment_gateway': {
        'show': 'مشاهده درگاه های پرداخت',
        'show_transactions': 'مشاهده گردش درگاه های پرداخت',
        'create': 'افزودن درگاه پرداخت',
        'update': 'ویرایش درگاه پرداخت',
        'delete': 'حذف درگاه پرداخت',
        'restore': 'بازگردانی درگاه پرداخت',
        'forceDelete': 'حذف دائم درگاه پرداخت'
      },
      'cash_menu': 'صندوق ها',
      'cash': {
        'show': 'مشاهده صندوق ها',
        'show_transactions': 'مشاهده گردش صندوق ها',
        'create': 'افزودن صندوق',
        'update': 'ویرایش صندوق',
        'delete': 'حذف صندوق',
        'restore': 'بازگردانی صندوق',
        'forceDelete': 'حذف دائم صندوق'
      },
      'payment_menu': 'پرداخت',
      'payment': {
        'show': 'مشاهده پرداخت',
        'create': 'افزودن پرداخت',
        'delete': 'حذف پرداخت',
        'restore': 'بازیابی پرداخت',
        'forceDelete': 'حذف دائم پرداخت',
        'dynamicDate': 'ثبت پرداخت برای گذشته'
      },
      'receive_menu': 'دریافت',
      'receive': {
        'show': 'مشاهده دریافت',
        'create': 'افزودن دریافت',
        'return': 'مرجوع دریافت',
        'delete': 'حذف دریافت',
        'restore': 'بازیابی دریافت',
        'forceDelete': 'حذف دائم دریافت',
        'dynamicDate': 'ثبت دریافت برای گذشته'
      },
      'cost_menu': 'هزینه',
      'cost': {
        'show': 'مشاهده هزینه',
        'create': 'افزودن هزینه',
        'delete': 'حذف هزینه',
        'restore': 'بازیابی هزینه',
        'forceDelete': 'حذف دائم هزینه',
        'dynamicDate': 'ثبت هزینه برای گذشته'
      },
      'internal_fund_menu': 'انتقالی وجه داخلی',
      'internal_fund': {
        'show': 'مشاهده انتقالی وجه داخلی',
        'create': 'افزودن انتقالی وجه داخلی',
        'delete': 'حذف انتقالی وجه داخلی',
        'restore': 'بازیابی انتقالی وجه داخلی',
        'forceDelete': 'حذف دائم انتقالی وجه داخلی',
        'dynamicDate': 'ثبت انتقالی وجه داخلی برای گذشته'
      },
      'accountancy_menu': 'حسابداری',
      'opening': {
        'access': 'دسترسی کامل اول دوره'
      },
      'document': {
        'access': 'دسترسی کامل اسناد'
      },
      'accounts_tree': 'درختواره حساب ها',
      'account': {
        'access': 'دسترسی کامل درختواره حساب ها',
        'force_update': 'ویرایش اجباری درختواره حساب ها'
      },
      'tax_menu': 'مالیات',
      'tax': {
        'show': 'مشاهده مالیات',
        'create': 'افزودن مالیات',
        'delete': 'حذف مالیات',
        'restore': 'بازیابی مالیات',
        'forceDelete': 'حذف دائم مالیات',
        'dynamicDate': 'ثبت مالیات برای گذشته'
      },
      'sales_menu': 'فروش',
      'sale_invoices_menu': 'فاکتورهای فروش',
      'sale_invoice': {
        'show': 'مشاهده فاکتورهای فروش',
        'create': 'افزودن فاکتور فروش',
        'update': 'ویرایش فاکتور فروش',
        'fullUpdate': 'ویرایش کامل فاکتور فروش',
        'complete': 'تکمیل فاکتور فروش',
        'refreshStatus': 'برگرداندن وضعیت فاکتور فروش',
        'delete': 'حذف موقت فاکتور فروش',
        'forceDelete': 'حذف دائم فاکتور فروش',
        'restore': 'بازیابی فاکتور فروش'
      },
      'return_sale_invoices_menu': 'فاکتورهای مرجوع فروش',
      'return_sale_invoice': {
        'show': 'مشاهده فاکتورهای موجوع فروش',
        'create': 'افزودن فاکتور موجوع فروش',
        'update': 'ویرایش فاکتور موجوع فروش',
        'fullUpdate': 'ویرایش کامل فاکتور موجوع فروش',
        'refreshStatus': 'برگرداندن وضعیت فاکتور موجوع فروش',
        'delete': 'حذف موقت فاکتور موجوع فروش',
        'forceDelete': 'حذف دائم فاکتور موجوع فروش',
        'restore': 'بازیابی فاکتور موجوع فروش'
      },
      'suggest_sale_invoice': {
        'show': 'مشاهده پیشنهادهای فروش کالا'
      },
      'purchases_menu': 'خرید',
      'purchase_invoices_menu': 'فاکتورهای خرید',
      'purchase_invoice': {
        'show': 'مشاهده فاکتورهای خرید',
        'create': 'افزودن فاکتور خرید',
        'update': 'ویرایش فاکتور خرید',
        'fullUpdate': 'ویرایش کامل فاکتور خرید',
        'refreshStatus': 'برگرداندن وضعیت فاکتور خرید',
        'delete': 'حذف موقت فاکتور خرید',
        'forceDelete': 'حذف دائم فاکتور خرید',
        'restore': 'بازیابی فاکتور خرید'
      },
      'purchase_invoice_lines_menu': 'اقلام فاکتورهای خرید',
      'purchase_invoice_line': {
        'create': 'افزودن اقلام فاکتور خرید',
        'update': 'ویرایش اقلام فاکتور خرید',
        'fullUpdate': 'ویرایش کامل اقلام فاکتور خرید',
        'delete': 'حذف اقلام فاکتور خرید'
      },
      'return_purchase_invoices': 'فاکتورهای مرجوع خرید',
      'return_purchase_invoice': {
        'show': 'مشاهده فاکتورهای موجوع خرید',
        'create': 'افزودن فاکتور موجوع خرید',
        'update': 'ویرایش فاکتور موجوع خرید',
        'fullUpdate': 'ویرایش کامل فاکتور موجوع خرید',
        'refreshStatus': 'برگرداندن وضعیت فاکتور موجوع خرید',
        'delete': 'حذف موقت فاکتور موجوع خرید',
        'forceDelete': 'حذف دائم فاکتور موجوع خرید',
        'restore': 'بازیابی فاکتور موجوع خرید'
      },
      'suggest_purchase_invoice': {
        'show': 'مشاهده پیشنهادهای خرید کالا'
      },
      'connector_menu': 'اتصال ساز',
      'shops_menu': 'فروشگاه ها',
      'shop': {
        'show': 'مشاهده فروشگاه ها',
        'create': 'افزودن فروشگاه جدید',
        'update': 'ویرایش فروشگاه',
        'update_setting': 'ویرایش پیرکربندی فروشگاه',
        'update_storeroom': 'ویرایش انبارهای فروشگاه',
        'delete': 'حذف موقت فروشگاه',
        'restore': 'بازیابی فروشگاه',
        'forceDelete': 'حذف دائم فروشگاه'
      },
      'woo_controllers_menu': 'کنترلرها',
      'woo_controller_attribute': {
        'access': 'دسترسی کامل کنترلر ویژگی'
      },
      'woo_controller_product': {
        'access': 'دسترسی کامل کنترلر کالا'
      },
      'woo_controller_product_price': {
        'access': 'دسترسی کامل کنترلر قیمت کالا'
      },
      'woo_controller_product_inventory': {
        'access': 'دسترسی کامل کنترلر موجودی کالا'
      },
      'woo_controller_user': {
        'access': 'دسترسی کامل کنترلر مشتریان'
      },
      'woo_controller_invoice': {
        'access': 'دسترسی کامل کنترلر سفارشات'
      },
      'woo_controller_payment_gateways': {
        'access': 'دسترسی کامل کنترلر درگاه های پرداخت'
      },
      'organizational_ticket_menu': 'تیکت سازمانی',
      'organizational_ticket': {
        'show': 'مشاهده تیکت های سازمانی',
        'create': 'ایجاد تیکت سازمانی',
        'delete': 'حذف تیکت سازمانی'
      },
      'financial_year_menu': 'سال های مالی',
      'financial_year': {
        'show': 'مشاهده سال مالی',
        'create': 'ایجاد سال مالی',
        'switch': 'تغییر سال مالی'
      },
      'setting_menu': 'تنظیمات',
      'setting': {
        'show': 'مشاهده تنظیمات',
        'update': 'ویرایش تنظیمات',
        'reset_application': 'بازنشانی سامانه'
      },
      'reports': 'گزارشات',
      'report_tops': {
        'show': 'مشاهده برترین ها'
      },
      'report_chart': {
        'show': 'مشاهده نمودار ها'
      },
      'gross_profit_report': {
        'show': 'مشاهده گزارش سود ناخالص'
      },
      'attributes_profit_report': {
        'show': 'مشاهده گزارش سود ناخالص ویژگی ها'
      },
      'activity_logs': {
        'show': 'مشاهده لاگ ها'
      },
      'log': {
        'show': 'دسترسی کامل لاگ ها'
      },
      'backup_menu': 'پشتیبان گیری',
      'backup': {
        'show': 'مشاهده آخرین فایل های پشتیبان',
        'create': 'ایجاد فایل پشتیبان',
        'restore': 'بازگردانی فایل پشتیبان'
      },
      'is_addons': 'افزودنی ها',
      'addons': {
        'woo_controller': 'کنترلر',
        'warehousing': 'انبارداری',
        'max_debt': 'سقف اعتبار',
        'points': 'امتیازدهی'
      }
    },

    insert: {
      title: 'افزودن گروه کاربری جدید',

      labels: {
        name: 'عنوان گروه کاربری ',
        allowedActiveSession: 'تعداد نشست های فعال مجاز',
        access: 'دسترسی',
        insert: 'افزودن',
        inMenu: 'منو',

        accessList: {
          manager: 'دسترسی مدیرکل',

          users: {
            totalAccess: 'دسترسی کامل اشخاص',
            show: 'مشاهده اشخاص',
            insert: 'افزودن شخص',
            update: 'ویرایش کامل',
            delete: 'حذف موقت  شخص',
            setRole: 'اختصاص نقش به شخص'
          },

          roles: 'دسترسی کامل نقش ها',
          treasury: {
            title: 'دسترسی کامل خزانه داری',

            bank: 'دسترسی کامل بانک ها',
            cash: 'دسترسی کامل صندوق ها'
          },

          reports: {
            title: 'دسترسی کامل گزارشات',
            logs: 'دسترسی لاگ ها'
          },

          delete: {
            title: 'حذف دائم',

            user: 'اشخاص',
            banks: 'بانک ها',
            cash: 'صندوق ها'
          }
        }
      },

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی 5 باشد',
        permissions: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    edit: {
      title: 'ویرایش گروه کاربری',

      validators: {
        name: 'نام گروه به صورت صحیح وارد نشده است',
        nameCantEmpty: 'نام نمی تواند  خالی باشد',
        activeSessionCount: 'تعداد سشن فعال بایستی کمتر و یا مساوی 5 باشد',
        permissions: 'هیچ دسترسی انتخاب نشده است'
      }
    },

    insertUserToAccessGroup: {

      notifications: {
        success: 'گروه دسترسی شخص مورد با موفقیت بروزسانی شد',
        error: 'بروزرسانی گروه دسترسی شخص مورد نظر با خطا همراه شد',
        noUserSelected: 'هیچ شخصی انتخاب نشده است'
      }
    },

    table: {

      header: {
        row: 'ردیف',
        name: 'عنوان دسترسی'
      }
    },

    actions: {
      insert: 'افزودن',
      save: 'ثبت'
    }
  },

  users: {
    title: 'تنظیمات اشخاص',

    labels: {
      gender: 'اجباری بودن جنسیت'
    }
  },

  security: {
    title: 'امنیت',

    labels: {
      disActive: 'مدت زمان خروج اجباری در صورت نداشتن فعالیت',
      minute: 'دقیقه'
    }
  },

  customize: {
    title: 'شخصی سازی سامانه',

    labels: {
      theme: 'انتخاب تم مورد نظر'
    }
  },

  financial: {
    title: 'مالی',

    rule: 'قوانین',
    tax: 'مالیات',
    paymentInfo: 'اطلاعات پرداخت',
    NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد',

    labels: {
      activeMaxDebt: 'سقف بدهکاری فعال باشد',
      purchaseTax: 'درصد مالیات خرید',
      saleTax: 'درصد مالیات فروش',
      vat: 'درصد مالیات ارزش افزوده',
      taxRegistrationNumber: 'شماره مالیاتی',
      bankName: 'نام بانک',
      accountName: 'نام حساب',
      accountNumber: 'شماره حساب',
      iban: 'شماره شبا',
      swiftCode: 'شناسه بانک',
      cardNumber: 'شماره کارت'
    },

    notifications: {
      negativeBalanceError: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد',
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات مالی با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات مالی با خطا همراه شد'
      }
    }
  },

  product: {
    title: 'کالا',

    labels: {
      addAttributeManually: 'ویژگی به صورت دستی به کالا اضافه شود'
    },

    notifications: {
      edit: {
        success: 'اطلاعات کالا با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات کالا با خطا همراه شد'
      }
    }
  },

  tax: {
    title: 'مالیات',

    labels: {
      enableTax: 'فعال سازی مالیات',
      purchaseTax: 'درصد مالیات خرید',
      saleTax: 'درصد مالیات فروش',
      taxRegistrationNumber: 'شماره مالیاتی',
      enableShippingTax: 'قیمت حمل و نقل شامل مالیات است'
    },

    notifications: {
      edit: {
        success: 'اطلاعات مالیات با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات مالیات با خطا همراه شد'
      }
    }
  },

  shipping: {
    title: 'حمل و نقل',

    labels: {
      cost: 'هزینه حمل و نقل'
    },

    notifications: {
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات حمل و نقل با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات حمل و نقل با خطا همراه شد'
      }
    }
  },

  print: {
    title: 'پرینت',

    labels: {
      logos: 'تصاویر',
      invoiceLogo: 'لوگوی فاگتور',
      postalLabelLogo: 'لوگوی رسید پستی',
      receiptLogo: 'لوگوی حواله انبار',
      treasuryLogo: 'لوگوی خزانه',
      companyNames: 'عنوان شرکت',
      invoiceCompanyName: 'نام شرکت در فاکتور',
      postalLabelCompanyName: 'نام شرکت در رسید پستی',
      postalLabelPrintCompanyName: 'نام شرکت در پرینت رسید پستی',
      warehouseReceiptCompanyName: 'نام شرکت در حواله انبار',
      treasuryCompanyName: 'نام شرکت در خزانه',
      lightLogo: 'لوگوی روشن',
      stamp: 'مهر شرکت',
      QR: 'QR',
      theme: 'قالب رسید پستی',
      companyInfoWithZeroVat: 'در صورتی که مالیات صفر بود، اطلاعات شرکت در پرینت فاکتور نمایش داده شود'
    },

    themeOptions: {
      theme1: 'A4 عمودی',
      theme2: 'A5 عمودی'
    },

    notifications: {
      wrongValues: 'اطلاعات وارد شده معتبر نمیباشد',

      edit: {
        success: 'اطلاعات پرینت با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات پرینت با خطا همراه شد'
      }
    }
  },

  financialYear: {
    title: 'سال مالی',

    statusTypes: {
      all: 'همه',
      active: 'فعال',
      disActive: 'غیر فعال'
    },

    table: {
      header: {
        rowNumber: 'ردیف',
        name: 'نام',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        status: 'وضعیت',
        show: 'مشاهده'
      }
    },

    notifications: {
      isActived: 'سال مالی مورد نظر قبلا فعال شده است',

      insert: {
        success: 'سال مالی جدید با موفقیت ثبت شد',
        error: 'ایجاد سال مالی جدید با خطا همراه شد'
      },

      edit: {
        success: 'ویرایش سال مالی با موفقیت انجام شد',
        error: 'ویرایش سال مالی با خطا همراه شد'
      },

      switch: {
        success: 'مشاهده سال مالی با موفقیت انجام شد',
        error: 'مشاهده سال مالی مورد نظر با خطا همراه شد'
      },

      parseError: {
        period: 'بازه زمانی سال مالی انتخاب شده معتبر نیست',
        paymentGateways: 'موجودی تعدادی از درگاه های پرداخت اعلام وصول نشده است!',
        storeroom: 'تعدادی از حواله های انبار تایید نشده اند!',
        periodNotEnded: 'سال مالی فعلی به اتمام نرسیده است!'
      }
    },

    confirmations: {
      insert: {
        title: 'تاییدیه ایجاد سال مالی جدید',
        body: 'آیا از ایجاد سال مالی جدید اطمینان دارید؟'
      },

      show: {
        title: 'تاییدیه مشاهده سال مالی',
        body: 'آیا از مشاهده سال مالی {name} اطمینان دارید؟'
      }
    },

    edit: {
      title: 'پیکره بندی سال مالی',

      periodTypes: {
        3: '3 ماهه',
        6: '6 ماهه',
        12: '1 ساله'
      },

      labels: {
        name: 'نام',
        startDate: 'تاریخ شروع',
        endDate: 'تاریخ پایان',
        period: 'مدت بازه سال مالی'
      }
    }
  },

  message: {
    title: 'پنل پیامکی',

    labels: {
      provider: 'ارائه دهنده',
      farazsms: 'فراز اس ام اس',
      smsglobal: 'SMS Global',
      farazsmsUsername: 'نام کاربری فراز اس ام اس',
      farazsmsPassword: 'رمز عبور فراز اس ام اس',
      farazsmsSender: 'شماره فرستنده فراز اس ام اس',
      smsglobalUsername: 'نام کاربری SMS Global',
      smsglobalPassword: 'رمز عبور SMS Global',
      smsglobalSender: 'شماره فرستنده SMS Global',
      messangerKey: 'کد احراز هویت',
      templates: 'قالب ها',
      login: 'قالب پیامک لاگین',
      signup: 'قالب پیامک ثبت نام',
      confirmInfo: 'قالب پیامک تایید اطلاعات'
    },

    notifications: {
      edit: {
        success: 'اطلاعات پنل پیامکی باشگاه با موفقیت بروز شد'
      }
    },

    invalidTexts: {
      onlyEnglish: 'فقط حروف انگلیسی مجاز است!'
    }
  },

  General: {
    title: 'عمومی',
    logo: {
      rule: 'فرمت های مجاز JPG, JPEG یا PNG. حداکثر حجم لوگو 800 کیلوبایت'
    },
    club: {
      code: 'کد شرکت',
      name: 'نام شرکت',
      managerName: 'نام و نام خانوادگی مدیر',
      address: 'آدرس',
      phoneNumber: 'شماره تماس',
      managerPhoneNumber: 'شماره تماس مدیر'
    }
  },

  Treasury: {
    title: 'خزانه',
    rule: 'قوانین',
    NegativeValue: 'بانک و صندوق قابلیت منفی شدن داشته باشد',

    notifications: {
      negativeBalanceError: 'به دلیل منفی بودن موجودی تعدادی از بانک ها و یا صندوق ها، ذخیره تنظیمات مورد نظر با خطا همراه شد',

      edit: {
        success: 'اطلاعات خزانه با موفقیت بروز شد',
        error: 'بروزرسانی اطلاعات خزانه با خطا همراه شد'
      }
    }
  },

  Locale: {
    title: 'زبان ها',
    language: 'زبان',
    basicSetting: 'تنظیمات پایه',

    labels: {
      basicSetting: 'تنظیمات پایه',
      defaultLanguage: 'زبان پیشفرض شرکت',
      defaultCurrency: 'واحد پولی پیشفرض شرکت',
      region: 'منطقه پیش فرض شرکت',
      defaultCountryCode: 'پیش شماره پیشفرض شرکت'
    },

    currency: {
      IRT: 'تومان',
      IRR: 'ریال',
      USD: 'دلار',
      AED: 'درهم',
      other: 'سایر'
    },

    notifications: {
      edit: {
        success: 'اطلاعات زبان با موفقیت به روز شد.',
        error: 'بروزرسانی اطلاعات زبان با خطا همراه شد.'
      }
    }
  },

  warehouse: {
    title: 'انبارداری',

    labels: {
      checkExitReceiptByRow: 'تایید سطری حواله خروج انبار'
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات انبارداری با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات انبارداری با خطا همراه شد.'
      },
      parseError: {
        defaultStoreroomForPersonalSale: 'انبار پیش فرض انتخابی معتبر نیست!'
      }
    }
  },

  purchase: {
    title: 'خرید',

    labels: {
      defaultStoreroom: 'انبار پیش فرض برای خرید',
      hasPrePurchaseInvoiceAge: 'پیش فاکتور خرید پس از مدتی حذف شود.',
      prePurchaseInvoiceAge: 'پیش فاکتور خرید بعد از ... روز حذف شود.',
      hasPreReturnPurchaseInvoiceAge: 'پیش فاکتور مرجوع خرید پس از مدتی حذف شود.',
      preReturnPurchaseInvoiceAge: 'پیش فاکتور مرجوع خرید بعد از ... روز حذف شود.'
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات خرید با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات خرید با خطا همراه شد!'
      },

      parseError: {
        preSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور خرید صحیح نیست!',
        preReturnSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور مرجوع خرید صحیح نیست!'
      }
    }
  },

  sale: {
    title: 'فروش',

    labels: {
      score: 'امتیاز',
      defaultStoreroom: 'انبار پیش فرض برای فروش حضوری',
      hasPreSaleInvoiceAge: 'پیش فاکتور فروش پس از مدتی حذف شود.',
      preSaleInvoiceAge: 'پیش فاکتور فروش بعد از ... روز حذف شود.',
      hasPreReturnSaleInvoiceAge: 'پیش فاکتور مرجوع فروش پس از مدتی حذف شود.',
      preReturnSaleInvoiceAge: 'پیش فاکتور مرجوع فروش بعد از ... روز حذف شود.',
      profitConversationRate: 'نرخ تبدیل سود به امتیاز',
      enableRetailShipping: 'فروش حضوری دارای حمل و نقل است',
      enableSalePoint: 'فعال کردن امتیاز فروش',
      defaultBank: 'بانک پیشفرض',
      defaultCash: 'صندوق پیشفرض',
      defaultPaymentGateway: 'درگاه پیشفرض'
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات فروش با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات فروش با خطا همراه شد!'
      },

      parseError: {
        preSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور فروش صحیح نیست!',
        preReturnSaleInvoiceAge: 'تعداد روز برای حذف پیش فاکتور مرجوع فروش صحیح نیست!'
      }
    }
  },

  reset: {
    title: 'بازنشانی سامانه',

    counts: {
      costs: '{count} هزینه',
      documents: '{count} سند ',
      financial_years: '{count} سال مالی ',
      internal_funds: '{count} انتقالی خزانه',
      invoices: '{count} فاکتور (خرید، مرجوع خرید، فروش، مرجوع فروش)',
      opening_inventories: '{count} اول دوره',
      payments: '{count} پرداخت',
      receives: '{count} دریافت',
      storeroom_receipts: '{count} خواله انبار'
    },

    labels: {
      companyName: 'نام سامانه',
      confirm: 'تائید، بازنشانی سامانه',
      codeValidate: 'اعتبار کد تا ',
      second: 'ثانیه',
      resendCode: 'ارسال دوباره کد',
      storedData: 'شما در حال حذف اطلاعات حسابداری سامانه هستید که شامل موارد زیر می باشد:',
      resetConfirm: 'آیا شما اطمینان دارید؟',
      enterConfirm: 'برای تائید، عبارت زیر را وارد نمایید:'
    },

    notifications: {
      otpExpired: 'کد ارسالی منقضی شده است',
      otpWrong: 'کد ارسالی اشتباه است',

      sendOtp: {
        success: 'کد تاییدیه بازنشانی سامانه برای شما ارسال شد',
        error: 'ارسال کد تاییدیه بازنشانی سامانه با خطا همراه شد',
        unSaveChanges: 'تغییرات ذخیره نشده در سامانه وجود دارد.'
      },
      confirmReset: {
        success: 'سامانه با موفقیت بازنشانی شد',
        error: 'بازنشانی سامانه با خطا همراه شد'
      }
    },

    confirmations: {
      reset: {
        title: 'بازنشانی سامانه',
        body: 'آیا از بازنشانی سامانه اطمینان دارید؟'
      }
    }
  },

  report: {
    title: 'گزارشات',

    labels: {
      email: 'ایمیل',
      daily: 'ایمیل های فعال برای گزارش روزانه',
      hooks: {
        purchase_invoice: {
          create: 'افزودن فاکتور خرید',
          update: 'ویرایش فاکتور خرید',
          delete: 'حذف فاکتور خرید'
        },
        return_purchase_invoice: {
          create: 'افزودن فاکتور مرجوع خرید',
          update: 'ویرایش فاکتور مرجوع خرید',
          delete: 'حذف فاکتور مرجوع خرید'
        },
        sale_invoice: {
          create: 'افزودن فاکتور فروش',
          update: 'ویرایش فاکتور فروش',
          delete: 'حذف فاکتور فروش'
        },
        return_sale_invoice: {
          create: 'افزودن فاکتور مرجوع فروش',
          update: 'ویرایش فاکتور مرجوع فروش',
          delete: 'حذف فاکتور مرجوع فروش'
        }
      }
    },

    notifications: {
      edit: {
        success: 'ویرایش تنظیمات گزارش با موفقیت انجام شد.',
        error: 'ویرایش تنظیمات گزارش با خطا همراه شد!'
      },

      parseError: {
        email: 'ایمیل وارد شده معتبر نیست',
        categories: 'هیچ دسته بندی انتخاب نشده است',
        emailExist: 'ایمیل وارد شده در لیست وجود داره'
      }
    },

    confirmations: {
      delete: {
        title: 'تاییدیه حذف ایمیل',
        body: 'آیا از حذف ایمیل {name} اطمینان دارید؟'
      }
    }
  }
}
