export default {
  list: {
    table: {
      header: {
        rowNumber: 'ردیف',
        user: 'کاربر',
        description: 'توضیحات',
        date: 'تاریخ'
      }
    },

    dialogs: {
      title: 'توضیحات',

      noChange: 'فیلدی تغییری داده نشده است'
    }
  },

  login: 'کاربر وارد سامانه شد',
  reset_app: 'سامانه بازنشانی شد',
  switch_active_financial_year: 'سال مالی سامانه تغییر کرد',

  user: {
    store: 'اطلاعات کاربر ایجاد شد',
    update: 'اطلاعات کاربر به روزرسانی شد',
    soft_destroy: 'اطلاعات کاربر حذف موقت شد',
    restore: 'اطلاعات کاربر بازگردانی شد',
    destroy: 'اطلاعات کاربر حذف شد',
    set_role: 'به کاربر نقش تخصیص داده شد',
    set_max_debt: 'سقف بدهکاری شخص به روز رسانی شد',
    set_group: 'گروه شخص ایجاد شد',
    remove_group: 'گروه شخص حذف شد'
  },

  user_address: {
    store: 'آدرس جدید به کاربر اضافه شد',
    update: 'آدرس کاربر به روز شد',
    destroy: 'آدرس کاربر حذف شد'
  },

  user_group: {
    store: 'اطلاعات گروه شخص ایجاد شد',
    update: 'اطلاعات گروه شخص بروزرسانی شد',
    soft_destroy: 'اطلاعات گروه شخص به صورت موقت حذف شد',
    restore: 'اطلاعات گروه شخص بازگردانی شد',
    destroy: 'اطلاعات گروه شخص حذف شد'
  },

  invoice: {
    store: 'اطلاعات فاکتور ایجاد شد',
    update: 'اطلاعات فاکتور بروزرسانی شد',
    full_update: 'اطلاعات فاکتور به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور بازگردانی شد',
    destroy: 'اطلاعات فاکتور حذف شد',
    complete: 'فاکتور پرداخت شد',
    refresh_status: 'وضعیت فاکتور تغییر داده شد'
  },

  sale_invoice: {
    store: 'اطلاعات فاکتور فروش ایجاد شد',
    update: 'اطلاعات فاکتور فروش بروزرسانی شد',
    full_update: 'اطلاعات فاکتور فروش به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور فروش به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور فروش بازگردانی شد',
    destroy: 'اطلاعات فاکتور فروش حذف شد',
    complete: 'فاکتور فروش پرداخت شد',
    refresh_status: 'وضعیت فاکتور فروش تغییر داده شد'
  },

  return_sale_invoice: {
    store: 'اطلاعات فاکتور مرجوع فروش ایجاد شد',
    update: 'اطلاعات فاکتور مرجوع فروش بروزرسانی شد',
    full_update: 'اطلاعات فاکتور مرجوع فروش به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور مرجوع فروش به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور مرجوع فروش بازگردانی شد',
    destroy: 'اطلاعات فاکتور مرجوع فروش حذف شد',
    complete: 'فاکتور مرجوع فروش پرداخت شد',
    refresh_status: 'وضعیت فاکتور مرجوع فروش تغییر داده شد'
  },

  purchase_invoice: {
    store: 'اطلاعات فاکتور خرید ایجاد شد',
    update: 'اطلاعات فاکتور خرید بروزرسانی شد',
    full_update: 'اطلاعات فاکتور خرید به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور خرید به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور خرید بازگردانی شد',
    destroy: 'اطلاعات فاکتور خرید حذف شد',
    complete: 'فاکتور خرید پرداخت شد',
    refresh_status: 'وضعیت فاکتور خرید تغییر داده شد'
  },

  return_purchase_invoice: {
    store: 'اطلاعات فاکتور مرجوع خرید ایجاد شد',
    update: 'اطلاعات فاکتور مرجوع خرید بروزرسانی شد',
    full_update: 'اطلاعات فاکتور مرجوع خرید به صورت اجباری بروزرسانی شد',
    soft_destroy: 'اطلاعات فاکتور مرجوع خرید به صورت موقت حذف شد',
    restore: 'اطلاعات فاکتور مرجوع خرید بازگردانی شد',
    destroy: 'اطلاعات فاکتور مرجوع خرید حذف شد',
    complete: 'فاکتور پرداخت شد',
    refresh_status: 'وضعیت فاکتور مرجوع خرید تغییر داده شد'
  },

  product: {
    store: 'اطلاعات کالا ایجاد شد',
    update: 'اطلاعات کالا بروزرسانی شد',
    soft_destroy: 'اطلاعات کالا حذف موقت شد',
    restore: 'اطلاعات کالا بازگردانی شد',
    destroy: 'اطلاعات کالا حذف شد',
    pricing: 'کالا قیمت گذاری شد'
  },

  role: {
    store: 'گروه دسترسی ایجاد شد',
    update: 'گروه دسترسی بروزرسانی شد',
    destroy: 'گروه دسترسی حذف شد'
  },

  receive: {
    store: 'رسید دریافت ایجاد شد',
    update: 'رسید دریافت بروزرسانی شد',
    soft_destroy: 'رسید دریافت حذف موقت شد',
    restore: 'رسید دریافت بازگردانی شد',
    destroy: 'رسید دریافت حذف شد'
  },

  payment: {
    store: 'رسید پرداخت ایجاد شد',
    update: 'رسید پرداخت بروزرسانی شد',
    soft_destroy: 'رسید پرداخت حذف موقت شد',
    restore: 'رسید پرداخت بازگردانی شد',
    destroy: 'رسید پرداخت حذف شد'
  },

  internal_fund: {
    store: 'رسید انتقالی خزانه ایجاد شد',
    update: 'رسید انتقالی خزانه بروزرسانی شد',
    soft_destroy: 'رسید انتقالی خزانه حذف موقت شد',
    restore: 'رسید انتقالی خزانه بازگردانی شد',
    destroy: 'رسید انتقالی خزانه حذف شد'
  },

  cost: {
    store: 'رسید هزینه ایجاد شد',
    update: 'رسید هزینه بروزرسانی شد',
    soft_destroy: 'رسید هزینه حذف موقت شد',
    restore: 'رسید هزینه بازگردانی شد',
    destroy: 'رسید هزینه حذف شد'
  },

  tax: {
    store: 'رسید مالیات ایجاد شد',
    update: 'رسید مالیات بروزرسانی شد',
    soft_destroy: 'رسید مالیات حذف موقت شد',
    restore: 'رسید مالیات بازگردانی شد',
    destroy: 'رسید مالیات حذف شد'
  },

  bank: {
    store: 'اطلاعات بانک ایجاد شد',
    update: 'اطلاعات بانک بروزرسانی شد',
    soft_destroy: 'اطلاعات بانک به صورت موقت حذف شد',
    restore: 'اطلاعات بانک بازگردانی شد',
    destroy: 'اطلاعات بانک حذف شد'
  },

  cash: {
    store: 'اطلاعات صندوق ایجاد شد',
    update: 'اطلاعات صندوق بروزرسانی شد',
    soft_destroy: 'اطلاعات صندوق به صورت موقت حذف شد',
    restore: 'اطلاعات صندوق بازگردانی شد',
    destroy: 'اطلاعات صندوق حذف شد'
  },

  payment_gateway: {
    store: 'اطلاعات درگاه پرداخت ایجاد شد',
    update: 'اطلاعات درگاه پرداخت بروزرسانی شد',
    soft_destroy: 'اطلاعات درگاه پرداخت به صورت موقت حذف شد',
    restore: 'اطلاعات درگاه پرداخت بازگردانی شد',
    destroy: 'اطلاعات درگاه پرداخت حذف شد'
  },

  service: {
    store: 'اطلاعات خدمت ایجاد شد',
    update: 'اطلاعات خدمت بروزرسانی شد',
    soft_destroy: 'اطلاعات خدمت به صورت موقت حذف شد',
    restore: 'اطلاعات خدمت بازگردانی شد',
    destroy: 'اطلاعات خدمت حذف شد'
  },

  storeroom_receipt: {
    store: 'اطلاعات حواله انبار ایجاد شد',
    accept: 'حواله انبار تایید شد',
    update: 'حواله انبار ویرایش شد'
  },

  storeroom: {
    store: 'اطلاعات انبار ایجاد شد',
    update: 'اطلاعات انبار بروزرسانی شد',
    soft_destroy: 'اطلاعات انبار به صورت موقت حذف شد',
    restore: 'اطلاعات انبار بازگردانی شد',
    destroy: 'اطلاعات انبار حذف شد'
  },

  storeroom_deficit: {
    store: 'اطلاعات کسورات و اضافات انبار ایجاد شد',
    update: 'اطلاعات کسورات و اضافات بروزرسانی شد',
    soft_destroy: 'اطلاعات کسورات و اضافات به صورت موقت حذف شد',
    restore: 'اطلاعات کسورات و اضافات بازگردانی شد',
    destroy: 'اطلاعات کسورات و اضافات حذف شد'
  },

  storeroom_conversion: {
    store: 'اطلاعات تبدیلات کالا ایجاد شد',
    update: 'اطلاعات تبدیلات کالا بروزرسانی شد',
    soft_destroy: 'اطلاعات تبدیلات کالا به صورت موقت حذف شد',
    restore: 'اطلاعات تبدیلات کالا بازگردانی شد',
    destroy: 'اطلاعات تبدیلات کالا حذف شد'
  },

  variablesLabel: {
    invoice: {
      type: {
        1: 'فروش',
        2: 'خرید',
        3: 'مرجوع فروش',
        4: 'مرجوع خرید'
      },
      action_type: {
        1: 'حضوری',
        2: 'تلفنی',
        3: 'اینترنتی'
      },
      status: {
        1: 'پیش فاکتور',
        2: 'فاکتور',
        3: 'خروج / رسید انبار',
        4: 'ناتمام',
        5: 'باطل شده'
      }
    },
    product: {
      type: {
        1: 'ساده',
        2: 'متغیر'
      },

      status: {
        0: 'عدم اتصال',
        1: 'پیش نویس',
        2: 'در انتظار',
        3: 'منتشر شده',
        4: 'خصوصی'
      }
    },
    receive: {
      status: {
        0: 'در انتظار',
        1: 'تایید شده'
      }
    },
    storeroom_deficit: {
      status: {
        0: 'پیش نویس',
        1: 'تایید شده'
      }
    },
    storeroom_conversion: {
      status: {
        0: 'پیش نویس',
        1: 'تایید شده'
      }
    },
    tax: {
      type: {
        1: 'مالیات بر درامد',
        2: 'مالیات بر ارزش افزوده'
      }
    },
    types: {
      store: 'افزودن',
      update: 'ویرایش',
      destroy: 'حذف',
      soft_destroy: 'حذف موقت',
      restore: 'بازگردانی',
      complete: 'تکمیل فاکتور فروش',
      accept: 'تایید حواله انبار',
      pricing: 'قیمت گذاری',
      login: 'ورود',
      full_update: 'ویرایش اجباری',
      reset_app: 'بازنشانی سامانه',
      set_group: 'ایجاد گروه شخص',
      remove_group: 'حذف گروه شخص'
    }
  },

  changeFields: {
    store: {
      user: {
        name: 'نام «{new}» ثبت شد.',
        family: 'نام خانوادگی «{new}» ثبت شد.',
        gender: 'جنسیت «{new}» ثبت شد.',
        avatar: 'عکس پروفایل کاربر ثبت شد',
        company: 'شرکت «{new}» ثبت شد.',
        phone_number: 'شماره تلفن «{new}» ثبت شد.',
        email: 'ایمیل «{new}» ثبت شد.',
        max_debt: 'سقف بدهکاری «{new}» ثبت شد.',
      },

      user_group: {
        name: 'نام «{new}» ثبت شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          province: 'استان «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          title: 'عنوان «{new}» ثبت شد.'
        }
      },

      product: {
        type: 'نوع کالا «{new}» ثبت شد.',
        title: 'نام «{new}» ثبت شد.',
        status: 'وضعیت کالا «{new}» ثبت شد.',
        slug: 'نامک «{new}» ثبت شد.',
        shop_title: 'نام نمایشی در فروشگاه «{new}» ثبت شد.',
        phase_out: 'توقف تولید «{new}» ثبت شد.',
        image: 'تصویر کالا ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        code: 'کد «{new}» ثبت شد.',
        category: 'دسته بندی «{new}» ثبت شد.',
        attributes: {
          attribute: 'ویژگی محصول «{new}» ثبت شد.',
          attribute_value: 'مقدار ویژگی «{new}» ثبت شد.',
          use_in_variable: 'استفاده ویژگی به عنوان متغیر «{new}» ثبت شد.'
        },
        variants: {
          barcode: 'بارکد «{new}» ثبت شد.',
          price: 'قیمت «{new}» ثبت شد.',
          sale_price: 'قیمت حراج «{new}» ثبت شد.',
          status: 'وضعیت کالا «{new}» ثبت شد.',
          variables: {
            barcode: 'بارکد متغیر «{new}» ثبت شد.',
            price: 'قیمت متغیر «{new}» ثبت شد.',
            sale_price: 'قیمت حراج متغیر «{new}» ثبت شد.',
            status: 'وضعیت متغیر «{new}» ثبت شد.',
            attribute: 'ویژگی متغیر «{new}» ثبت شد.',
            attribute_value: 'مقدار ویژگی متغیر «{new}» ثبت شد.',
            variables: {
              attribute: 'ویژگی متغیر «{new}» ثبت شد.',
              attribute_value: 'مقدار ویژگی متغیر «{new}» ثبت شد.'
            }
          }
        }
      },

      service: {
        category: 'دسته بندی «{new}» ثبت شد.',
        title: 'نام «{new}» ثبت شد.',
        short_title: 'نام نمایشی در فروشگاه «{new}» ثبت شد.',
        slug: 'نامک «{new}» ثبت شد.',
        code: 'کد «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        sale_price: 'قیمت حراج «{new}» ثبت شد.',
        image: 'عکس خدمت بروزرسانی شد',
        description: 'توضیحات «{new}» ثبت شد.',
        max_select: 'حداکثر تعداد انتخاب «{new}» ثبت شد.',
        attributes: {
          attribute: 'ویژگی «{new}» ثبت شد.',
          attribute_value: 'مقدار ویژگی «{new}» ثبت شد.'
        }
      },

      bank: {
        name: 'نام «{new}» ثبت شد.',
        card_number: 'شماره کارت «{new}» ثبت شد.',
        account_number: 'شماره حساب «{new}» ثبت شد.',
        shaba_number: 'شماره شبا «{new}» ثبت شد.'
      },

      cash: {
        name: 'نام «{new}» ثبت شد.'
      },

      payment_gateway: {
        type: 'نوع درگاه پرداخت «{new}» ثبت شد.',
        title: 'عنوان درگاه «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        bank: 'بانک تسویه «{new}» ثبت شد.',
        enabled: 'وضعیت درگاه پرداخت «{new}» ثبت شد.',
        end_time: 'ساعت پایان روز کاری «{new}» ثبت شد.',
        merchant_code: 'کد پذیرنده «{new}» ثبت شد.',
        terminal_code: 'کد پایانه «{new}» ثبت شد.'
      },

      cost: {
        payer: 'طرف حساب «{new}» ثبت شد.',
        account: 'سرفصل هزینه «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      internal_fund: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        wage: 'کارمزد «{new}» ثبت شد.',
        reference: 'شماره مرجع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      payment: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      receive: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        payee: 'دریافت کننده «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        reference: 'شماره مرجع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      tax: {
        payer: 'پرداخت کننده «{new}» ثبت شد.',
        from: 'بازه شروع «{new}» ثبت شد.',
        to: 'بازه پایان «{new}» ثبت شد.',
        description: 'توضیحات «{new}» ثبت شد.',
        price: 'قیمت «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      storeroom: {
        name: 'نام «{new}» ثبت شد.'
      },

      storeroom_receipt: {
        destination: 'مقصد «{new}» ثبت شد.',
        invoice: 'شماره فاکتور «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        date: 'تاریخ «{new}» ثبت شد.',
        stocks: {
          storeroom: 'انبار «{new}» ثبت شد.',
          product_variant: 'متعیر محصول «{new}» ثبت شد.',
          quantity: 'تعداد محصول «{new}» ثبت شد.',
          unit_price: 'قیمت واحد «{new}» ثبت شد.',
          status: 'وضعیت «{new}» ثبت شد.'
        }
      },

      storeroom_deficit: {
        description: 'توضیحات «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        stocks: {
          product_variant: 'کالای «{new}» ثبت شد.',
          storeroom: 'انبار «{new}» ثبت شد.',
          quantity: 'تعداد «{new}» ثبت شد.',
        }
      },

      storeroom_conversion: {
        description: 'توضیحات «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        stocks: {
          source_product_variant: 'کالای مبدا «{new}» ثبت شد.',
          source_quantity: 'تعداد کالای مبدا «{new}» ثبت شد.',
          destination_product_variant: 'کالای مقصد «{new}» ثبت شد.',
          destination_quantity: 'تعداد کالای مقصد «{new}» ثبت شد.',
          storeroom: 'انبار «{new}» ثبت شد.'
        }
      },

      role: {
        name: 'نام «{new}» ثبت شد.',
        parent: 'والد «{new}» ثبت شد.',
        allowed_active_sessions: 'تعداد نشست های فعال «{new}» ثبت شد.',
        permissions: {
          id: 'شناسه دسترسی «{new}» ثبت شد.',
          name: 'نام دسترسی «{new}» ثبت شد.',
          slug: 'نامک دسترسی «{new}» ثبت شد.'
        }
      },

      invoice: {
        user: 'طرف حساب «{new}» ثبت شد.',
        invoice_number: 'شماره فاکتور «{new}» ثبت شد.',
        type: 'نوع «{new}» ثبت شد.',
        action_type: 'نوع رزرو «{new}» ثبت شد.',
        status: 'وضعیت «{new}» ثبت شد.',
        invoice_date: 'تاریخ فاکتور «{new}» ثبت شد.',
        details: 'جزئیات «{new}» ثبت شد.',
        note: 'یادداشت «{new}» ثبت شد.',
        shipping: 'هزینه حمل و نقل «{new}» ثبت شد.',
        gift: 'هدیه «{new}» ثبت شد.',
        gift_account: 'سرفصل هدیه «{new}» ثبت شد.',
        tax_rate: 'درصد مالیات «{new}» ثبت شد.',
        total_tax: 'مالیات کل «{new}» ثبت شد.',
        total_discount: 'تخفیف کل «{new}» ثبت شد.',
        final_price: 'قیمت نهایی «{new}» ثبت شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        },
        address: {
          province: 'استان «{new}» ثبت شد.',
          city: 'شهر «{new}» ثبت شد.',
          address: 'آدرس «{new}» ثبت شد.',
          post_code: 'کد پستی «{new}» ثبت شد.',
          receiver_name: 'نام گیرنده «{new}» ثبت شد.',
          receiver_family: 'نام خانوادگی گیرنده «{new}» ثبت شد.',
          receiver_call_number: 'تلفن گیرنده «{new}» ثبت شد.'
        },
        lines: {
          product_variant: 'متغیر محصول «{new}» ثبت شد.',
          storeroom: 'انبار «{new}» ثبت شد.',
          unit_price: 'قیمت واحد «{new}» ثبت شد.',
          quantity: 'تعداد محصول «{new}» ثبت شد.',
          discount: 'تخفیف «{new}» ثبت شد.',
          tax: 'مالیات «{new}» ثبت شد.'
        },
        services: {
          service: 'خدمت «{new}» ثبت شد.',
          quantity: 'تعداد «{new}» ثبت شد.',
          required: 'اجباری بودن خدمت «{new}» ثبت شد.',
          unit_price: 'قیمت واحد «{new}» ثبت شد.',
          discount: 'تخفیف «{new}» ثبت شد.',
          cancel_price: 'قیمت لغو کردن «{new}» ثبت شد.',
          vat: 'مالیات «{new}» ثبت شد.'
        },
        cooperations: {
          cooperator: 'همکار فروش «{new}» ثبت شد.',
          amount: 'مبلغ همکاری فروش «{new}» ثبت شد.'
        }
      }
    },

    update: {
      switch_active_financial_year: {
        financial_year: 'سال مالی به «{new}» بروزرسانی شد'
      },

      user: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        family: 'نام خانوادگی از «{old}» به «{new}» بروزرسانی شد.',
        gender: 'جنسیت از «{old}» به «{new}» بروزرسانی شد.',
        avatar: 'عکس پروفایل کاربر بروزرسانی شد',
        company: 'شرکت از «{old}» به «{new}» بروزرسانی شد.',
        phone_number: 'شماره تلفن از «{old}» به «{new}» بروزرسانی شد.',
        email: 'ایمیل از «{old}» به «{new}» بروزرسانی شد.',
        max_debt: 'سقف بدهکاری از «{old}» به «{new}» بروزرسانی شد.',
      },

      user_group: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
      },

      user_address: {
        addresses: {
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          title: 'عنوان از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      product: {
        type: 'نوع کالا بروزرسانی شد.',
        title: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت کالا بروزرسانی شد.',
        slug: 'نامک از «{old}» به «{new}» بروزرسانی شد.',
        shop_title: 'نام نمایشی در فروشگاه از «{old}» به «{new}» بروزرسانی شد.',
        phase_out: 'توقف تولید از «{old}» به «{new}» بروزرسانی شد.',
        image: 'تصویر کالا بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        code: 'کد از «{old}» به «{new}» بروزرسانی شد.',
        category: 'دسته بندی از «{old}» به «{new}» بروزرسانی شد.',
        attributes: {
          attribute: 'ویژگی محصول از «{old}» به «{new}» بروزرسانی شد.',
          attribute_value: 'مقدار ویژگی از «{old}» به «{new}» بروزرسانی شد.',
          use_in_variable: 'استفاده ویژگی به عنوان متغیر از «{old}» به «{new}» بروزرسانی شد.'
        },
        variants: {
          barcode: 'بارکد از «{old}» به «{new}» بروزرسانی شد.',
          price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
          sale_price: 'قیمت حراج از «{old}» به «{new}» بروزرسانی شد.',
          status: 'وضعیت کالا بروزرسانی شد.',
          variables: {
            barcode: 'بارکد متغیر از «{old}» به «{new}» بروزرسانی شد.',
            price: 'قیمت متغیر از «{old}» به «{new}» بروزرسانی شد.',
            sale_price: 'قیمت حراج متغیر از «{old}» به «{new}» بروزرسانی شد.',
            status: 'وضعیت متغیر از «{old}» به «{new}» بروزرسانی شد.',
            attribute: 'ویژگی متغیر از «{old}» به «{new}» بروزرسانی شد.',
            attribute_value: 'مقدار ویژگی متغیر از «{old}» به «{new}» بروزرسانی شد.',
            variables: {
              attribute: 'ویژگی متغیر از «{old}» به «{new}» بروزرسانی شد.',
              attribute_value: 'مقدار ویژگی متغیر از «{old}» به «{new}» بروزرسانی شد.'
            }
          }
        }
      },

      service: {
        category: 'دسته بندی از «{old}» به «{new}» بروزرسانی شد.',
        title: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        short_title: 'نام نمایشی در فروشگاه از «{old}» به «{new}» بروزرسانی شد.',
        slug: 'نامک از «{old}» به «{new}» بروزرسانی شد.',
        code: 'کد از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        sale_price: 'قیمت حراج از «{old}» به «{new}» بروزرسانی شد.',
        image: 'عکس خدمت بروزرسانی شد',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        max_select: 'حداکثر تعداد انتخاب از «{old}» به «{new}» بروزرسانی شد.',
        attributes: {
          attribute: 'ویژگی از «{old}» به «{new}» بروزرسانی شد.',
          attribute_value: 'مقدار ویژگی از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      bank: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        card_number: 'شماره کارت از «{old}» به «{new}» بروزرسانی شد.',
        account_number: 'شماره حساب از «{old}» به «{new}» بروزرسانی شد.',
        shaba_number: 'شماره شبا از «{old}» به «{new}» بروزرسانی شد.'
      },

      cash: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.'
      },

      payment_gateway: {
        type: 'نوع درگاه پرداخت بروزرسانی شد',
        title: 'عنوان درگاه از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        bank: 'بانک تسویه از «{old}» به «{new}» بروزرسانی شد.',
        enabled: 'وضعیت درگاه پرداخت بروزرسانی شد.',
        end_time: 'ساعت پایان روز کاری از «{old}» به «{new}» بروزرسانی شد.',
        merchant_code: 'کد پذیرنده از «{old}» به «{new}» بروزرسانی شد.',
        terminal_code: 'کد پایانه از «{old}» به «{new}» بروزرسانی شد.'
      },

      cost: {
        payer: 'طرف حساب از «{old}» به «{new}» بروزرسانی شد.',
        account: 'سرفصل هزینه از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      internal_fund: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        wage: 'کارمزد از «{old}» به «{new}» بروزرسانی شد.',
        reference: 'شماره مرجع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      payment: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      receive: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        payee: 'دریافت کننده از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        reference: 'شماره مرجع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      tax: {
        payer: 'پرداخت کننده از «{old}» به «{new}» بروزرسانی شد.',
        from: 'بازه شروع از «{old}» به «{new}» بروزرسانی شد.',
        to: 'بازه پایان از «{old}» به «{new}» بروزرسانی شد.',
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        price: 'قیمت از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        }
      },

      storeroom: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.'
      },

      storeroom_receipt: {
        destination: 'مقصد از «{old}» به «{new}» بروزرسانی شد.',
        invoice: 'شماره فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        date: 'تاریخ از «{old}» به «{new}» بروزرسانی شد.',
        stocks: {
          storeroom: 'انبار از «{old}» به «{new}» بروزرسانی شد.',
          product_variant: 'متعیر محصول از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد محصول از «{old}» به «{new}» بروزرسانی شد.',
          unit_price: 'قیمت واحد از «{old}» به «{new}» بروزرسانی شد.',
          status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      storeroom_deficit: {
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        stocks: {
          product_variant: 'کالا از «{old}» به «{new}» بروزرسانی شد.',
          storeroom: 'انبار از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      storeroom_conversion: {
        description: 'توضیحات از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        stocks: {
          source_product_variant: 'کالای مبدا از «{old}» به «{new}» بروزرسانی شد.',
          source_quantity: 'تعداد کالای مبدا از «{old}» به «{new}» بروزرسانی شد.',
          destination_product_variant: 'کالای مقصد از «{old}» به «{new}» بروزرسانی شد.',
          destination_quantity: 'تعداد کالای مقصد از «{old}» به «{new}» بروزرسانی شد.',
          storeroom: 'انبار از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      role: {
        name: 'نام از «{old}» به «{new}» بروزرسانی شد.',
        parent: 'والد از «{old}» به «{new}» بروزرسانی شد.',
        allowed_active_sessions: 'تعداد نشست های فعال از «{old}» به «{new}» بروزرسانی شد.',
        permissions: {
          id: 'شناسه دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          name: 'نام دسترسی از «{old}» به «{new}» بروزرسانی شد.',
          slug: 'نامک دسترسی از «{old}» به «{new}» بروزرسانی شد.'
        }
      },

      invoice: {
        user: 'طرف حساب از «{old}» به «{new}» بروزرسانی شد.',
        invoice_number: 'شماره فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        type: 'نوع از «{old}» به «{new}» بروزرسانی شد.',
        action_type: 'نوع رزرو از «{old}» به «{new}» بروزرسانی شد.',
        status: 'وضعیت از «{old}» به «{new}» بروزرسانی شد.',
        invoice_date: 'تاریخ فاکتور از «{old}» به «{new}» بروزرسانی شد.',
        details: 'جزئیات از «{old}» به «{new}» بروزرسانی شد.',
        note: 'یادداشت از «{old}» به «{new}» بروزرسانی شد.',
        shipping: 'هزینه حمل و نقل از «{old}» به «{new}» بروزرسانی شد.',
        gift: 'هدیه از «{old}» به «{new}» بروزرسانی شد.',
        gift_account: 'سرفصل هدیه از «{old}» به «{new}» بروزرسانی شد.',
        tax_rate: 'درصد مالیات از «{old}» به «{new}» بروزرسانی شد.',
        total_tax: 'مالیات کل از «{old}» به «{new}» بروزرسانی شد.',
        total_discount: 'تخفیف کل از «{old}» به «{new}» بروزرسانی شد.',
        final_price: 'قیمت نهایی از «{old}» به «{new}» بروزرسانی شد.',
        attachments: {
          path: 'پیوست ایجاد شد'
        },
        address: {
          province: 'استان از «{old}» به «{new}» بروزرسانی شد.',
          city: 'شهر از «{old}» به «{new}» بروزرسانی شد.',
          address: 'آدرس از «{old}» به «{new}» بروزرسانی شد.',
          post_code: 'کد پستی از «{old}» به «{new}» بروزرسانی شد.',
          receiver_name: 'نام گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_family: 'نام خانوادگی گیرنده از «{old}» به «{new}» بروزرسانی شد.',
          receiver_call_number: 'تلفن گیرنده از «{old}» به «{new}» بروزرسانی شد.'
        },
        lines: {
          product_variant: 'متغیر محصول از «{old}» به «{new}» بروزرسانی شد.',
          storeroom: 'انبار از «{old}» به «{new}» بروزرسانی شد.',
          unit_price: 'قیمت واحد از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد محصول از «{old}» به «{new}» بروزرسانی شد.',
          discount: 'تخفیف از «{old}» به «{new}» بروزرسانی شد.',
          tax: 'مالیات از «{old}» به «{new}» بروزرسانی شد.'
        },
        services: {
          service: 'خدمت از «{old}» به «{new}» بروزرسانی شد.',
          quantity: 'تعداد از «{old}» به «{new}» بروزرسانی شد.',
          required: 'اجباری بودن خدمت از «{old}» به «{new}» بروزرسانی شد.',
          unit_price: 'قیمت واحد از «{old}» به «{new}» بروزرسانی شد.',
          discount: 'تخفیف از «{old}» به «{new}» بروزرسانی شد.',
          cancel_price: 'قیمت لغو کردن از «{old}» به «{new}» بروزرسانی شد.',
          vat: 'مالیات از «{old}» به «{new}» بروزرسانی شد.'
        },
        cooperations: {
          cooperator: 'همکار فروش از «{old}» به «{new}» بروزرسانی شد.',
          amount: 'مبلغ همکاری فروش از «{old}» به «{new}» بروزرسانی شد.'
        }
      }
    }
  }
}
